import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classNames from "classnames";
import styles from "../../assets/styles/NewsPage.module.css";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import Input from "../elements/Input";
import Button from "../elements/Button";
import axios from "axios";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const NewsPage = ({
  news,
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "testimonial section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "testimonial-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: news?.title,
    paragraph: news?.short_description,
  };

  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [form, setForm] = useState({
    news_id: news.id,
    user_email: "",
    user_full_name: "",
    user_comment: "",
  });

  const handleInput = (e) => {
    const { id, value } = e.target;

    setForm((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const send = () => {
    setLoading(true);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_BASE_URL}/user/comment`,
      data: form,
    })
      .then((res) => {
        toast.success("Commentaire ajouté avec succès");
        setLoading(false);

        setForm((prev) => ({
          ...prev,
          user_email: "",
          user_full_name: "",
          user_comment: "",
        }));
        getComments();
      })
      .catch((err) => {
        toast.error("Veuillez réessayer");
        setLoading(false);
      });
  };

  const getComments = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/data/comments/get/${news.id}`,
        {
          params: {},
        }
      )
      .then(function (response) {
        setComments(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  useEffect(() => {
    getComments();
    setForm((prev) => ({
      ...prev,
      news_id: news.id,
    }));
  }, [news]);

  return (
    <section {...props} className={outerClasses}>
      <ToastContainer />
      <div className="container">
        <div className={innerClasses}>
          <div className={tilesClasses}>
            <Image
              src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/newsImages/${news?.image}`}
              alt="Open"
            />
          </div>

          <SectionHeader data={sectionHeader} className="center-content" />
        </div>

        <div
          dangerouslySetInnerHTML={{
            __html: news.description,
          }}
        />
        <div className={styles.author}>
          <h6>{news?.author_name}</h6>
          <i>{news?.created_at?.split("T")[0]}</i>
        </div>

        <div>
          <div className={styles.comments_section}>
            <h4>Commentaires</h4>
            <hr className={styles.line} />

            <div>
              {comments.length > 0 ? (
                comments?.map((comment, index) => (
                  <div className={styles.comment} key={index}>
                    <p>{comment.user_full_name}</p>
                    <h6>{comment.user_comment}</h6>
                  </div>
                ))
              ) : (
                <h6>Aucun commentaire pour l'instant !</h6>
              )}
            </div>

            <hr className={styles.line} />
          </div>

          <h5>Laisser un commentaire</h5>

          <div>
            <div className={styles.userInfo}>
              <Input
                onChange={handleInput}
                id="user_email"
                type="email"
                label="Email"
                labelHidden
                placeholder="Votre email"
                value={form.user_email}
              ></Input>

              <Input
                onChange={handleInput}
                id="user_full_name"
                type="text"
                label="Nom"
                labelHidden
                placeholder="Votre nom complet"
                value={form.user_full_name}
              ></Input>
            </div>
            <div className={styles.textArea}>
              <Input
                onChange={handleInput}
                id="user_comment"
                type="textarea"
                label="Commentaire"
                labelHidden
                hasIcon="right"
                placeholder="Votre commentaire"
                value={form.user_comment}
              ></Input>
            </div>
            <div className={styles.cta} style={{ cursor: 'pointer' }}>
              <Button
                tag="a"
                color="primary"
                wideMobile
                onClick={(e) => {
                  e.preventDefault();
                  send(); // Only call send here
                }}
                style={{ width: '100%', height: '100%' }}
              >
                Ajouter un commentaire
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

NewsPage.propTypes = propTypes;
NewsPage.defaultProps = defaultProps;

export default NewsPage;
