import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import styles from "../../assets/styles/NewsBriefSection.module.css";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { Link, useParams } from "react-router-dom";
const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const CategoryNewsPage = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  news,
  ...props
}) => {
  const params = useParams();
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: `${params.category} News`,
    paragraph: "All related news",
  };

  return (
    <section
      className={`${styles.whiteSection} ${styles.full_width} ${styles.clearfix} ${styles.coursesSection}`}
      id="News"
    >
      <SectionHeader data={sectionHeader} className="center-content" />
      {/* {news?.length > 0 &&
        news?.map((section, index) => ( */}
      <div className={styles.container}>
        {/* <div className={`${styles.sectionTitle} ${styles.text_center}`}>
              <h2>
                <span
                  className={`${styles.shape} ${styles.shape_left} ${styles.bg_color_4}`}
                ></span>
                <span>{Object.keys(section)[0]} News</span>
                <span
                  className={`${styles.shape} ${styles.shape_right} ${styles.bg_color_4}`}
                ></span>
              </h2>
            </div> */}

        <div className={styles.row}>
          {news.map((card, index) => (
            <div
              className={`${styles.col_sm_3} ${styles.col_xs_12} ${styles.block}`}
            >
              <div className={`${styles.thumbnail} ${styles.thumbnailContent}`}>
                <Link to={`/news/${params.category}/${card.news_id}`}>
                  {/* <a href=""> */}
                  <img
                    src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/newsImages/${card?.news_image}`}
                    alt="image"
                    className={styles.img_responsive}
                  />
                  {/* </a> */}
                </Link>
                <div className={`${styles.caption} ${styles.border_color_1}`}>
                  <h3>
                    <a href="" className={styles.color_1}>
                      {card?.news_title}
                    </a>
                  </h3>
                  <p>{card?.news_short_description}</p>
                  <ul className={`${styles.list_inline} ${styles.btn_yellow}`}>
                    <li>
                      <a href="" className={`${styles.btn} ${styles.btn_link}`}>
                        <i
                          className={`${styles.fa} ${styles.fa_angle_double_right}`}
                          aria-hidden="true"
                        >
                          <KeyboardDoubleArrowRightIcon
                            fontSize="small"
                            sx={{ fontSize: 11 }}
                          />
                        </i>
                        Voir plus
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* ))} */}
    </section>
  );
};

CategoryNewsPage.propTypes = propTypes;
CategoryNewsPage.defaultProps = defaultProps;

export default CategoryNewsPage;
