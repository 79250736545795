import React, { useEffect, useState } from "react";
import Hero from "../components/sections/Hero";
import Carousel from "react-material-ui-carousel";
import useMediaQuery from "@mui/material/useMediaQuery";
import Image from "../components/elements/Image";
import axios from "axios";
import NewsBrief from "../components/sections/NewsBrief";
import FeaturesSplit from "../components/sections/FeaturesSplit";
import LinkSection from "../components/sections/LinkSection";
import ServicesSection from "../components/sections/ServicesSection";
import ContactBanner from "../components/sections/ContactBanner";
import EmployeesCarousel from "../components/sections/EmployeesCarousel";
import Partners from "../components/sections/Partners";

const Home = () => {
  const matches = useMediaQuery("(max-width:600px)");

  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  const getPageData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/data/pages/get`, {
        params: { name: "Home" },
      });

      const data = response.data;
      
      // Process and filter items
      const updatedItems = [
        data.image1 && {
          id: 1,
          imgSrc: `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${data.image1}`,
          text: data.text1 || 'Default text',
          link: data.link1 || '#',
        },
        data.image2 && {
          id: 2,
          imgSrc: `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${data.image2}`,
          text: data.text2 || 'Default text',
          link: data.link2 || '#',
        },
        data.image3 && {
          id: 3,
          imgSrc: `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${data.image3}`,
          text: data.text3 || 'Default text',
          link: data.link3 || '#',
        },
        data.image4 && {
          id: 4,
          imgSrc: `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${data.image4}`,
          text: data.text4 || 'Default text',
          link: data.link4 || '#',
        },
        data.image5 && {
          id: 5,
          imgSrc: `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${data.image5}`,
          text: data.text5 || 'Default text',
          link: data.link5 || '#',
        },
      ].filter(Boolean); // Remove falsy values

      setItems(updatedItems);
    } catch (error) {
      console.log(error.response);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPageData();
  }, []);

  const Item = ({ item }) => {
    if (!item) {
      return <div>Loading...</div>; // Provide fallback UI
    }

    const { imgSrc = 'default-image.png', text = 'Default text', link = '#' } = item;

    return (
      <div style={{ position: "relative", zIndex: -1 }}>
        <a href={link} target="_blank" rel="noreferrer noopener">
          <Image
            style={{
              margin: "auto",
              height: "80vh",
              objectFit: "cover",
              zIndex: -1,
            }}
            src={imgSrc}
            alt=""
            width={"100%"}
            height={"100%"}
          />
        </a>
        <div
          style={{
            position: "absolute",
            top: matches ? "25%" : "25%",
            left: 20,
          }}
        >
          <div style={{ fontSize: '35px' }}>
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className="carousel-container"
        style={{ paddingTop: "80px", zIndex: -1 }}
      >
        <Carousel interval={10000} indicators={false}>
          {items.map((item, i) => (
            <Item key={i} item={item} />
          ))}
        </Carousel>
      </div>
      <LinkSection />
      <ServicesSection />
      <ContactBanner />
      <NewsBrief />
      <EmployeesCarousel />
      {/* <Partners /> */}
    </>
  );
};

export default Home;
