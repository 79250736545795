import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import styles from "../../assets/styles/NewsBriefSection.module.css";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { Link } from "react-router-dom";
import axios from "axios";
import CustomHero from "./CustomHero";
const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const AllNewsPage = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  news,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: " ",
    paragraph: " ",
  };
  const [data, setData] = useState({});
  const getPageData = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/pages/get`, {
        params: {
          name: "News",
        },
      })
      .then(function (response) {
        setData((prev) => ({
          ...prev,
          image: `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/pagesFiles/${response.data.image1}`,
          text: response.data.text1,
        }));
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };
  useEffect(() => {
    getPageData();
  }, []);

  return (
    <section
      className={`${styles.whiteSection} ${styles.full_width} ${styles.clearfix} ${styles.coursesSection}`}
      id="News"
    >
      <CustomHero title="TOUTES LES ACTUALITÉS" image={data?.image} page="News" />

      <SectionHeader data={sectionHeader} className="center-content" />
      {news?.length > 0 &&
        news?.map((section, index) => (
          <div className={styles.container}>
            <div className={`${styles.sectionTitle} ${styles.text_center}`}>
              <h2>
                <span
                  className={`${styles.shape} ${styles.shape_left} ${styles.bg_color_4}`}
                ></span>
                <span>{Object.keys(section)[0]} Actualités</span>
                <span
                  className={`${styles.shape} ${styles.shape_right} ${styles.bg_color_4}`}
                ></span>
              </h2>
            </div>

            <div className={styles.row}>
              {section[Object.keys(section)[0]].map((card, index) => (
                <div
                  className={`${styles.col_sm_3} ${styles.col_xs_12} ${styles.block}`}
                >
                  <div
                    className={`${styles.thumbnail} ${styles.thumbnailContent}`}
                  >
                    <Link
                      to={`/news/${Object.keys(section)[0]}/${card.news_id}`}
                    >
                      {/* <a href=""> */}
                      <img
                        src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/newsImages/${card?.news_image}`}
                        alt="image"
                        className={styles.img_responsive}
                      />
                      {/* </a> */}
                    </Link>
                    <div
                      className={`${styles.caption} ${styles.border_color_1}`}
                    >
                      <h3>
                        <a href="" className={styles.color_1}>
                          {card?.news_title}
                        </a>
                      </h3>
                      <p>{card?.news_short_description}</p>
                      <ul
                        className={`${styles.list_inline} ${styles.btn_yellow}`}
                      >
                        <li>
                          <a
                            href=""
                            className={`${styles.btn} ${styles.btn_link}`}
                          >
                            <i
                              className={`${styles.fa} ${styles.fa_angle_double_right}`}
                              aria-hidden="true"
                            >
                              <KeyboardDoubleArrowRightIcon
                                fontSize="small"
                                sx={{ fontSize: 11 }}
                              />
                            </i>
                            Voir plus
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link
                style={{
                  color: "#008cb7",
                  float: "right",
                  textDecoration: "unset",
                }}
                to={`/news/${Object.keys(section)[0]} `}
              >
                Afficher tout
              </Link>
            </div>
          </div>
        ))}
    </section>
  );
};

AllNewsPage.propTypes = propTypes;
AllNewsPage.defaultProps = defaultProps;

export default AllNewsPage;
