import React from "react";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";

const CustomDataTable = (props) => {
  const options = {
    responsive: "scrollMaxHeight",
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: props.isLoading ? (
          <LinearProgress />
        ) : (
          "There is no matching data to display"
        ),
      },
    },
  };
  return (
    <ThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        options={props.options ? props.options : options}
        columns={props.columns}
        data={props.isLoading ? [] : props.data}
        title={props.title}
        // components={props.components ?? null}
      />
    </ThemeProvider>
  );
};
CustomDataTable.propTypes = {
  isLoading: PropTypes.any,
  theme: PropTypes.any,
  options: PropTypes.any,
  columns: PropTypes.any,
  data: PropTypes.any,
  title: PropTypes.any,
};

const getMuiTheme = () =>
  createTheme({
    components: {
      MUIDataTable: {
        styleOverrides: {
          root: {
            responsiveScroll: {
              maxHeight: "500px",
            },
            responsiveScrollMaxHeight: {
              maxHeight: "440px !important",
            },
          },

          responsiveBase: {
            maxHeight: "75vh !important",
          },
        },
      },

      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            textAlign: "center",
            fontSize: "14px",
          },
        },
      },

      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            textAlign: "center",
            fontSize: "16px",
          },

          data: { textAlign: "center", fontSize: "16px" },
          contentWrapper: {
            justifyContent: "center",
          },
        },
      },

      MuiButtonBase: {
        styleOverrides: {
          root: {
            textAlign: "center",
            fontSize: "14px",
          },
        },
      },

      MuiButton: {
        styleOverrides: {
          root: {
            textAlign: "center",
            fontSize: "14px",
          },
        },
      },

      MUIDataTablePagination: {
        styleOverrides: {
          toolbar: {
            alignItems: "baseline",
          },
        },
      },

      MuiTablePagination: {
        styleOverrides: {
          selectLabel: {
            fontSize: "14px",
          },
          displayedRows: {
            fontSize: "14px",
          },
          select: {
            fontSize: "14px",
          },
        },
      },

      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      },
    },
  });

// function getMuiTheme() {
//   return createTheme({
//     overrides: {
//       MUIDataTable: {
//         root: { backgroundColor: "red" },
//         paper: {
//           boxShadow: "none",
//         },
//         responsiveBase: {
//           height: "calc(100vh - 180px)!important",
//         },
//       },
//       MUIDataTableBodyRow: {
//         root: {
//           "&:nth-child(odd)": {
//             backgroundColor: "#ebebeb",
//           },
//         },
//       },
//       MUIDataTableBodyCell: {
//         root: {
//           paddingTop: "2px",
//           paddingBottom: "2px",
//           fontSize: "url.92em",
//         },
//       },

//       MuiTableCell: {
//         root: {
//           paddingTop: "2px",
//           paddingBottom: "2px",
//           fontSize: "0.92em",
//         },
//       },
//     },
//   });
// }

export default CustomDataTable;
