import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import CustomHero from "./CustomHero";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const AboutUsSection = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  data,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "Laboratoires St. Elie",
    paragraph: "",
  };

  return (
    <section {...props} className={outerClasses} style={{ marginTop: 0, marginBottom: 0 }}>
      <CustomHero title="À propos de nous" image={data?.image} page="About us" />
      <div className="container" style={{ marginTop: 0, marginBottom: 0 }}>
        <div className={innerClasses} style={{ marginTop: 0, marginBottom: 0 }}>
          <div className={tilesClasses} style={{ marginTop: 0, marginBottom: 0 }}>
            {/* <Image
              src={data?.image}
              alt="About Us"
              style={{ marginTop: 0, marginBottom: 0 }} // Ensure top and bottom margins are 0
            /> */}
          </div>
          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}

          <div style={{ marginTop: 0, marginBottom: 0 }} dangerouslySetInnerHTML={{ __html: data.text1 }} />
        </div>
      </div>

      <div className="container" style={{ marginTop: 0, marginBottom: 0 }}>
        <div style={{ textAlign: "center", marginTop: 0, marginBottom: 0 }}>
          <h3 style={{ marginTop: 0, marginBottom: 0 }}>Notre Mission et Comment Nous Avons Commencé</h3>
          <div style={{ marginTop: 0, marginBottom: 0 }} dangerouslySetInnerHTML={{ __html: data.text2 }} />
        </div>
        <div style={{ marginTop: 0, marginBottom: 0 }}>
          <b>
            {/* <i>Dr. Ghassan Hallak </i> */}
          </b>
        </div>

        {/* <i>Managing Director</i> */}
      </div>
    </section>
  );
};

AboutUsSection.propTypes = propTypes;
AboutUsSection.defaultProps = defaultProps;

export default AboutUsSection;
