import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import styles from "../../assets/styles/ServicesSection.module.css";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import axios from "axios";
import { Link } from "react-router-dom";
const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const ServicesSection = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  const sectionHeader = {
    title: "Workflow that just works",
    paragraph:
      "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum — semper quis lectus nulla at volutpat diam ut venenatis.",
  };

  const [services, setServices] = useState([]);

  const getServices = () => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/data/services/get`, {
        params: {},
      })
      .then(function (response) {
        setServices(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error.response);
      });
  };

  useEffect(() => {
    getServices();
  }, []);

  return (
    <section
      className={`${styles.whiteSection} ${styles.full_width} ${styles.clearfix} ${styles.coursesSection}`}
      id="Services"
    >
      <div className={styles.container}>
        <div className={`${styles.sectionTitle} ${styles.text_center}`}>
          <h2>
            <span
              className={`${styles.shape} ${styles.shape_left} ${styles.bg_color_4}`}
            ></span>
            <span>Nos Services</span>
            <span
              className={`${styles.shape} ${styles.shape_right} ${styles.bg_color_4}`}
            ></span>
          </h2>
        </div>

        <div className={styles.row}>
          {services?.map((service, index) => (
            <div
              className={`${styles.col_sm_3} ${styles.col_xs_12} ${styles.block}`}
            >
              <div className={`${styles.thumbnail} ${styles.thumbnailContent}`}>
                <Link to={`/service/${service.id}`}>
                  <img
                    src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/servicesImages/${service?.image}`}
                    alt="image"
                    className={styles.img_responsive}
                  />
                </Link>
                <div className={`${styles.caption} ${styles.border_color_1}`}>
                  <h3>
                    <a href="" className={styles.color_1}>
                      {service.title}
                    </a>
                  </h3>
                  <p>{service.short_description}</p>
                  <ul className={`${styles.list_inline} ${styles.btn_yellow}`}>
                    <li>
                      <Link
                        to={`/service/${service.id}`}
                        className={`${styles.btn} ${styles.btn_link}`}
                      >
                        <i
                          className={`${styles.fa} ${styles.fa_angle_double_right}`}
                          aria-hidden="true"
                        >
                          <KeyboardDoubleArrowRightIcon
                            fontSize="small"
                            sx={{ fontSize: 11 }}
                          />
                        </i>
                        Voir plus
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

ServicesSection.propTypes = propTypes;
ServicesSection.defaultProps = defaultProps;

export default ServicesSection;
